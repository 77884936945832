var createButton = function (label, onClick) {
    var button = document.createElement("button");
    button.textContent = label;
    button.addEventListener("click", onClick);
    return button;
};
var createLabel = function (textContent, className, id) {
    var label = document.createElement("label");
    if (className)
        label.className = className;
    if (id)
        label.id = id;
    label.textContent = textContent;
    return label;
};
export { createButton, createLabel };
