import { Watch } from "./Watch";
var WatchModel = /** @class */ (function () {
    function WatchModel() {
        var _this = this;
        this.getWatches = function () {
            return _this.watches;
        };
        this.addWatch = function (watch) {
            _this.watches.push(watch);
            _this.notify();
        };
        this.createWatch = function (timeZone) {
            try {
                _this.watches.push(new Watch(timeZone));
                _this.notify();
            }
            catch (error) {
                console.error(error);
            }
        };
        this.increaseWatchTime = function (id) {
            _this.watches.at(id).increase();
            _this.notify();
        };
        this.toggleWatchMode = function (id) {
            _this.watches.at(id).toggleMode();
            _this.notify();
        };
        this.toggleWatchLight = function (id) {
            _this.watches.at(id).toggleLight();
            _this.notify();
        };
        this.resetWatch = function (id) {
            _this.watches.at(id).reset();
            _this.notify();
        };
        this.toggleDisplayFormat = function () {
            _this.watches.forEach(function (watch) {
                watch.toggleTimeFormat();
            });
            _this.notify();
        };
        this.watches = [];
        this.observers = [];
    }
    WatchModel.prototype.attach = function (observer) {
        if (this.observers.includes(observer))
            return;
        this.observers.push(observer);
    };
    WatchModel.prototype.detach = function (observer) {
        var observerIndex = this.observers.indexOf(observer);
        if (observerIndex === -1)
            return;
        this.observers.splice(observerIndex, 1);
    };
    WatchModel.prototype.notify = function () {
        for (var _i = 0, _a = this.observers; _i < _a.length; _i++) {
            var observer = _a[_i];
            observer.update(this.watches);
        }
    };
    WatchModel.getInstance = function () {
        if (!WatchModel.instance) {
            WatchModel.instance = new WatchModel();
        }
        return WatchModel.instance;
    };
    return WatchModel;
}());
export { WatchModel };
