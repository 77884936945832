var WatchController = /** @class */ (function () {
    function WatchController(watchModel) {
        var _this = this;
        this.createWatch = function (timeZone) {
            timeZone = timeZone === "" ? "UTC" : timeZone;
            _this.watchModel.createWatch(timeZone);
        };
        this.toggleDisplayFormat = function () {
            _this.watchModel.toggleDisplayFormat();
        };
        this.increaseWatchTime = function (id) {
            _this.watchModel.increaseWatchTime(id);
        };
        this.toggleWatchMode = function (id) {
            _this.watchModel.toggleWatchMode(id);
        };
        this.toggleWatchLight = function (id) {
            _this.watchModel.toggleWatchLight(id);
        };
        this.resetWatch = function (id) {
            _this.watchModel.resetWatch(id);
        };
        this.watchModel = watchModel;
    }
    return WatchController;
}());
export { WatchController };
