import { EditHourState } from "./EditHoursState";
var NormalState = /** @class */ (function () {
    function NormalState() {
        this.toString = function () {
            return "Normal";
        };
    }
    NormalState.prototype.handleMode = function (watch) {
        watch.setState(new EditHourState());
    };
    NormalState.prototype.handleIncrease = function (watch) { }; // Nothing happens
    return NormalState;
}());
export { NormalState };
