import { NormalState } from "./NormalState";
var Watch = /** @class */ (function () {
    function Watch(timeZone) {
        var _this = this;
        this.setState = function (state) {
            _this.state = state;
        };
        this.toggleTimeFormat = function () {
            _this.timeFormat === "24"
                ? (_this.timeFormat = "12")
                : (_this.timeFormat = "24");
        };
        this.toggleMode = function () {
            _this.state.handleMode(_this);
        };
        this.getLightStatus = function () {
            return _this.lightOn;
        };
        this.getTimeOffset = function () {
            return _this.timeOffset;
        };
        this.getTimeZone = function () {
            return _this.timeZone;
        };
        this.getCurrentMode = function () {
            return _this.state.toString();
        };
        this.currentTime = new Date();
        this.state = new NormalState();
        this.lightOn = false;
        this.timeFormat = "24";
        this.timeZone = timeZone;
        this.timeOffset = { hours: 0, minutes: 0 };
    }
    Watch.prototype.increase = function () {
        this.state.handleIncrease(this);
    };
    Watch.prototype.toggleLight = function () {
        this.lightOn = !this.lightOn;
    };
    Watch.prototype.reset = function () {
        this.timeOffset.hours = 0;
        this.timeOffset.minutes = 0;
    };
    Watch.prototype.getCurrentTime = function () {
        this.currentTime = new Date(); // update time
        // Apply modifications
        this.currentTime.setHours(this.currentTime.getHours() + this.timeOffset.hours);
        this.currentTime.setMinutes(this.currentTime.getMinutes() + this.timeOffset.minutes);
        // Change format
        return new Intl.DateTimeFormat("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            timeZone: this.timeZone,
            hour12: this.timeFormat == "24" ? false : true,
        }).format(this.currentTime);
    };
    return Watch;
}());
export { Watch };
