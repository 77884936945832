import "./styles/index.css";
import { WatchModel, WatchView, WatchController } from "./ts";
document.addEventListener("DOMContentLoaded", function () {
    try {
        var watchModel = WatchModel.getInstance();
        var controller = new WatchController(watchModel);
        var watchView_1 = new WatchView("root", controller);
        watchModel.attach(watchView_1);
        controller.createWatch("Europe/Paris");
        // Rendering
        watchView_1.render();
        var intervalId = setInterval(function () { return watchView_1.render(); }, 1000); // Re-render every 1s
    }
    catch (error) {
        console.error(error);
    }
});
