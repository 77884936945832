import { createButton, createLabel } from "../../utils";
var WatchView = /** @class */ (function () {
    function WatchView(targetDivId, contoller) {
        var _this = this;
        this.validateTimezone = function (timeZone) {
            // Validating whether the input timezone is conformant to the Intl.DateTimeFormat() formats
            var regex = /^([+-])(\d{2}(:\d{2})?)$/;
            return regex.test(timeZone);
        };
        this.container = document.getElementById(targetDivId);
        if (!this.container) {
            throw new Error("Element with id ".concat(targetDivId, " not found."));
        }
        this.controller = contoller;
        this.watches = [];
        this.watchesContainer = document.createElement("div");
        this.timeZone = document.createElement("input");
        this.timeZone.placeholder = "Timezone (+/- hh:mm)";
        this.addButton = createButton("Create Watch", function () {
            if (!_this.validateTimezone(_this.timeZone.value))
                return alert("Invalid timezone input");
            _this.controller.createWatch(_this.timeZone.value);
        });
        this.toggleButton = createButton("Toggle Display Format", function () {
            return _this.controller.toggleDisplayFormat();
        });
        this.container.appendChild(this.addButton);
        this.container.appendChild(this.timeZone);
        this.container.appendChild(this.toggleButton);
    }
    WatchView.prototype.update = function (watchList) {
        this.watches = watchList;
        this.render();
    };
    WatchView.prototype.render = function () {
        var _this = this;
        this.watchesContainer.innerHTML = "";
        this.watches.forEach(function (watch, index) {
            var watchContainer = document.createElement("div");
            watchContainer.className = "watchContainer";
            watch.getLightStatus()
                ? (watchContainer.className += " light-bg")
                : (watchContainer.className += " dark-bg");
            var time = createLabel(watch.getCurrentTime(), "timeLabel");
            watchContainer.appendChild(time);
            var timeZoneLabel = createLabel("(Time zone: ".concat(watch.getTimeZone(), ")"), "subLabel");
            watchContainer.appendChild(timeZoneLabel);
            var modeLabel = createLabel("Mode: ".concat(watch.getCurrentMode()), "subLabel");
            watchContainer.appendChild(modeLabel);
            var modeButton = createButton("Mode", function () {
                return _this.controller.toggleWatchMode(index);
            });
            watchContainer.appendChild(modeButton);
            var increaseButton = createButton("Increase", function () {
                return _this.controller.increaseWatchTime(index);
            });
            watchContainer.appendChild(increaseButton);
            var lightButton = createButton("Light", function () {
                return _this.controller.toggleWatchLight(index);
            });
            watchContainer.appendChild(lightButton);
            var resetButton = createButton("Reset", function () {
                return _this.controller.resetWatch(index);
            });
            watchContainer.appendChild(resetButton);
            _this.watchesContainer.appendChild(watchContainer);
        });
        this.container.appendChild(this.watchesContainer);
    };
    return WatchView;
}());
export { WatchView };
